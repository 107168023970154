<template>
  <div>
    <v-card>
        <v-card-title>New Menu Navigation</v-card-title>

        <v-toolbar dark>
        <v-menu offset-y min-width="500">
          <template v-slot:activator="{ on }">
            <v-btn text class=" ml-0" v-on="on">
              Product
              <v-icon color="" class="ml-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-container class="pb-0">
            <v-row>
                <v-col cols="6">
                    <h4>Benefits</h4>
                    <v-list class="pb-0">
                    <template v-for="(item, i) in [{to:null, text: 'Teams and Collective'},{to:null, text: 'Custom Maps'},{to:null, text: 'Full Customization'},{to:null, text: 'Global Availability'},{to:null, text: 'Security and Data Privacy'},{to:null, text: 'Personal Goals'}]">
                        <v-list-item :key="i" link :to="item.to" exact class="px-0">
                        <v-list-item-content>
                            <v-list-item-title>
                            {{ item.text_key ? ( item.text_key.indexOf('.') > 0 ? $t(item.text_key) : $t('nav.account.' + item.text_key)) : item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-divider />
                    <v-list-item link class="px-0"> View All</v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="6">
                    <h4>All Challenges</h4>
                    <v-list class="pb-0">
                    <template v-for="(item, i) in [{to:null, text: 'Step Challenges'},{to:null, text: 'Time-based Challenges'},{to:null, text: 'Distance Challenges'},{to:null, text: 'Wellbeing Challenges'},{to:null, text: 'Custom Challenges'},{to:null, text: 'Pre-Designed Challenges'}]">
                        <v-list-item :key="i" link :to="item.to" exact class="px-0">
                        <v-list-item-content>
                            <v-list-item-title>
                            {{ item.text_key ? ( item.text_key.indexOf('.') > 0 ? $t(item.text_key) : $t('nav.account.' + item.text_key)) : item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-divider />
                    <v-list-item link class="px-0"> View All</v-list-item>
                    </v-list>
                </v-col>
            </v-row>
            </v-container>
          </v-card>
        </v-menu>  
        <v-menu offset-y min-width="500">
          <template v-slot:activator="{ on }">
            <v-btn text class=" ml-0" v-on="on">
              Solutions
              <v-icon color="" class="ml-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-container class="pb-0">
            <v-row>
                <v-col cols="6">
                    <h4>Solutions For</h4>
                    <v-list class="pb-0">
                    <template v-for="(item, i) in [{to:null, text: 'Workplace'},{to:null, text: 'Remote Working'},{to:null, text: 'Education'},{to:null, text: 'Re-sellers and Agents'},{to:null, text: 'Custom Apps and Platforms'}]">
                        <v-list-item :key="i" link :to="item.to" exact class="px-0">
                        <v-list-item-content>
                            <v-list-item-title>
                            {{ item.text_key ? ( item.text_key.indexOf('.') > 0 ? $t(item.text_key) : $t('nav.account.' + item.text_key)) : item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                    </v-list>
                </v-col>
                <v-col cols="6">
                    <h4>Integrations</h4>
                    <v-list class="pb-0">
                    <template v-for="(item, i) in [{to:null, text: 'Fitness Trackers'},{to:null, text: 'Microsoft Teams'},{to:null, text: 'Slack'},{to:null, text: 'Single Sign-On'},{to:null, text: 'Developers / API'}]">
                        <v-list-item :key="i" link :to="item.to" exact class="px-0">
                        <v-list-item-content>
                            <v-list-item-title>
                            {{ item.text_key ? ( item.text_key.indexOf('.') > 0 ? $t(item.text_key) : $t('nav.account.' + item.text_key)) : item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                    </v-list>
                </v-col>
            </v-row>
            </v-container>
            <v-divider/>
            <v-card-text>
                For sport events, local communities, and non-profits we offer <strong><a href="https://sodisp.com">sodisp.com</a></strong><br/>
            </v-card-text>
          </v-card>
        </v-menu>  
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text class=" ml-0" v-on="on">
              Resources
              <v-icon color="" class="ml-1">fal fa-angle-down</v-icon>
            </v-btn>
          </template>
          <v-card>
                    <v-list class="pb-0">
                    <template v-for="(item, i) in [{to:null, text: 'Blog'},{to:null, text: 'Customer Success Stories'},{to:null, text: 'Step Converter'},{to:null, text: 'Help and Support'}]">
                        <v-list-item :key="i" link :to="item.to" exact>
                        <v-list-item-content>
                            <v-list-item-title>
                            {{ item.text_key ? ( item.text_key.indexOf('.') > 0 ? $t(item.text_key) : $t('nav.account.' + item.text_key)) : item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                    </v-list>
          </v-card>
        </v-menu>  

        <v-btn>Pricing</v-btn>  
        <v-btn>Contact</v-btn>  
        </v-toolbar>

        <v-card-text></v-card-text>
        <v-subheader>Generated Menu</v-subheader>

        <cms-menu />
        
        <v-card-text></v-card-text>

        <v-card-text></v-card-text>
        <v-subheader>Generated Sidebar</v-subheader>

        <cms-drawer ref="drawer" />
        

        <v-card-text>
          <v-btn @click="$refs.drawer.expanded = !$refs.drawer.expanded">Toggle on/off</v-btn>
        </v-card-text>
    </v-card>
  </div>
</template>

<style lang="scss">
.allow-wrap { white-space: inherit !important;;}
</style>

<script>
import { mapGetters } from "vuex";
import siteData from '@/data/site.json'
import navData from '@/data/nav.json'
import tenants from '@/data/tenants.config'
import cmsService from "@/services/cmsService";
import menuJson from "@/data/cms/nav-menu-cofi.json";
import CmsMenu from '@/components/cms/CmsMenu.vue';
import CmsDrawer from '@/components/cms/CmsDrawer.vue';

const tenant = tenants.current();

export default {
  name: "AdminDesign",
  components: {
    CmsMenu,
    CmsDrawer,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      navData: navData,
      sidebar: false,
      menu: null,
        tenant: tenant,
        developers: ['C#', 'JS', 'C++', '...']
    };
  },
  async mounted() {
    this.menu = menuJson;//await cmsService.getData('nav-menu-cofi');
    console.log('Menu', this.menu, menuJson); 
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>


